import React, { useContext } from "react";
import { FaLinkedinIn, FaSun } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FiGithub } from "react-icons/fi";
import { RiMoonClearFill } from "react-icons/ri";
import "./Socialmedia.css";
import { ThemeContext } from "./Theme";

export default function Socialmedia() {
  const { toggleTheme } = useContext(ThemeContext);
  const { theme } = useContext(ThemeContext);

  if (theme === "light") {
    return (
      <div className="Socialmedia container">
        <div className="Socialmedia-countainer-light">
          <div className="theme-toggle" onClick={() => toggleTheme()}>
            <RiMoonClearFill size="30px" className="theme-icon" />
          </div>
          <a
            href="https://www.linkedin.com/in/chie-hayama-098a54181/"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedinIn size="25px" />
          </a>
          <a
            href="mailto:hayama.chie@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <MdEmail size="25px" />
          </a>
          <a
            href="https://github.com/hayamania"
            target="_blank"
            rel="noreferrer"
          >
            <FiGithub size="25px" />
          </a>
        </div>
      </div>
    );
  } else {
    return (
      <div className="Socialmedia container">
        <div className="Socialmedia-countainer-dark">
          <div className="theme-toggle" onClick={() => toggleTheme()}>
            <FaSun size="30px" className="theme-icon" />
          </div>
          <a
            href="https://www.linkedin.com/in/chie-hayama-098a54181/"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedinIn size="25px" />
          </a>
          <a
            href="mailto:hayama.chie@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <MdEmail size="25px" />
          </a>
          <a
            href="https://github.com/hayamania"
            target="_blank"
            rel="noreferrer"
          >
            <FiGithub size="25px" />
          </a>
        </div>
      </div>
    );
  }
}
