import React, { useContext } from "react";
import "./Project.css";
import dictionary from "./assets/dictionary_app.png";
import weather from "./assets/weather_react.png";
import todolist from "./assets/todolist.png";
import kingsbakery from "./assets/kingsbakery.png";
import { ThemeContext } from "./Theme";

export default function Project() {
  const { themeBtn } = useContext(ThemeContext);
  let projectUrl = [
    {
      img: kingsbakery,
      url: "https://kings-bakery.netlify.app/",
      alt: "Bakery shop website",
      discription: "Bakary website using React and Google Map API",
      tools: "React, API, BootStrap",
    },
    {
      img: dictionary,
      url: "https://clinquant-lolly-a74fa2.netlify.app/",
      alt: "Dictionary App",
      discription:
        "Dictionary application using React, API. Definitions of a word, images can be sarched.",
      tools: "React, API, BootStrap",
    },
    {
      img: weather,
      url: "https://neon-weather-app.netlify.app/",
      alt: "Weather React App",
      discription: "Weather application using React, API.",
      tools: "React, API, BootStrap",
    },
    {
      img: todolist,
      url: "https://react-simple-to-do-list.netlify.app/",
      alt: "Simple To Do List App",
      discription: "Simple to do list using React hooks",
      tools: "React, BootStrap",
    },
  ];
  console.log(projectUrl);
  return (
    <div className="Project">
      <h2 className="project-title">PROJECTS</h2>
      {projectUrl.map((project, index) => (
        <div className="row project-row" key={index}>
          <div className="col-sm-6">
            <img
              src={project.img}
              alt={project.alt}
              className="img-fluid img-thumbnail"
            />
          </div>
          <div className="col-sm-6">
            <p>{project.discription}</p>
            <p>Tech: {project.tools}</p>
            <div className="text-end">
              <a
                href={project.url}
                target="_blank"
                rel="noreferrer"
                className={themeBtn}
              >
                Check
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
