import "./App.css";
import Profile from "./Profile";
import Project from "./Project";
import Motion from "./Motion";
import Socialmedia from "./Socialmedia";
import React, { useContext } from "react";
import { ThemeContext } from "./Theme";

function App() {
  const { theme } = useContext(ThemeContext);
  return (
    <div className={theme}>
      <div className={`App`}>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <Profile />
            </div>
            <div className="col-md-4 d-none d-md-block">
              <Motion />
            </div>
          </div>
          <Socialmedia />
        </div>
        <Project />
        <footer>©2022 Chie Hayama</footer>
      </div>
    </div>
  );
}

export default App;
