import React, { useContext } from "react";
import "./Profile.css";
import CV from "./assets/CV.pdf";
import { ThemeContext } from "./Theme";

export default function Profile() {
  const { themeBtn } = useContext(ThemeContext);
  const { theme } = useContext(ThemeContext);
  const skills = [
    "HTML",
    "CSS",
    "JavaScript",
    "React",
    "Bootstrap",
    "Material-UI",
    "Adobe Suite",
  ];
  return (
    <div className="Profile">
      <h1>Chie Hayama</h1>
      <h2>Frontend Developer</h2>
      <h3>Skills</h3>
      <ul className={theme}>
        {skills.map((skill, index) => (
          <li key={index}> {skill} </li>
        ))}
      </ul>
      <a href={CV} download className={themeBtn}>
        Download CV
      </a>
    </div>
  );
}
