import React, { useRef, useEffect, useContext } from "react";
import { gsap } from "gsap";
import "./Motion.css";
import { ThemeContext } from "./Theme";

export default function () {
  const circleRef = useRef();
  const q = gsap.utils.selector(circleRef);
  const { theme } = useContext(ThemeContext);
  useEffect(() => {
    gsap.to(q(".circle"), {
      x: 200,
      stagger: 0.4,
      repeat: -1,
      repeatDelay: 1.5,
      yoyo: true,
    });
  }, []);

  if (theme === "light") {
    return (
      <div className="Motion" ref={circleRef}>
        <div className="circle dark" ref={circleRef}></div>
        <div className="circle dark" ref={circleRef}></div>
        <div className="circle dark" ref={circleRef}></div>
      </div>
    );
  } else {
    return (
      <div className="Motion" ref={circleRef}>
        <div className="circle light" ref={circleRef}></div>
        <div className="circle light" ref={circleRef}></div>
        <div className="circle light" ref={circleRef}></div>
      </div>
    );
  }
}
